"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";

import styles from "./Carousel.module.scss";
import For from "@/components/common/For";
import H2 from "@/components/common/H2";
import AnimateIn from "@/components/common/AnimateIn";
import Image from "@/components/common/Image";
import RightArrow from "@/components/common/RightArrow";
import clsx from "clsx";
import { Minus, Plus } from "lucide-react";
import If from "@/components/common/If";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { BackgroundColors, BackgroundColorText, Padding } from "@/config/site";
import { BlockProps } from "@/lib/types";
import { Heading } from "@/components/ui/Heading";
import { Button } from "@/components/actions/Button";
import { CTAs } from "@/components/ui/CTAs";
import Parallax from "@/components/common/Parallax";
import Card from "./Card";
import useResizeObserver from "@/hooks/useResizeObserver";

interface CarouselProps {
  block: BlockProps;
}

const getPrefix = (tags) => {
  let prefix = "";
  const prefixes = {
    "Case Study": "/case-studies",
    Solution: "/solutions",
    Category: "/categories",
    Series: "/series",
    Marketing: "/markets",
  };
  if (tags.length > 0) {
    tags.some((tag) => {
      const hasPrefix = prefixes[tag.name];
      if (hasPrefix) {
        prefix = hasPrefix;
        return true;
      }
    });
  }
  return prefix;
};

const CaseStudy = ({ thumbnail, index, currentIndex, ...props }) => {
  return (
    <div
      className={`stack embla__slide ml-0 relative ${
        styles.slide
      } ${currentIndex === index ? styles.active : ""}`}
    >
      <Image
        src={thumbnail?.cardImage?.[0]?.cdn_url}
        alt={`Thumbnail ${index}`}
        className={`transition-all w-full duration-500 ease-in-out aspect-[847/513] ${
          currentIndex === index ? "filter-none" : " grayscale"
        }`}
        imgClassName="aspect-[1/1] md:aspect-[847/513] w-full"
        maxWidth="2000"
      />
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-40 mix-blend-multiply z-0"></div>
      <div
        className={`px-8 pb-8 absolute bottom-0 left-0 stack gap-12 ${
          styles.title
        } ${currentIndex === index ? "opacity-100" : "opacity-0"}`}
      >
        <div className="stack gap-6">
          <h2 className="text-h3 text-white font-hero font-400">
            {thumbnail.title}
          </h2>
          <div className="h-[5px] bg-gold w-[50px]" />
        </div>
        <Button
          isLight
          href={`/case-studies/${thumbnail.handle}`}
          className="w-max max-w-full"
        >
          View Case Study
        </Button>
      </div>
    </div>
  );
};

const Swatch = ({ thumbnail, index, currentIndex, ...props }) => {
  const isHPDL = false;
  // const isHPDL = thumbnail.contentfulMetadata?.tags?.some(
  //   (tag) => tag.name === "HPDL Swatch",
  // );
  return (
    <div
      className={`stack ${isHPDL ? "flex-swatch" : "flex-card"} ml-0 relative`}
    >
      <Image
        src={thumbnail?.image?.[0]?.cdn_url}
        alt={`Thumbnail ${index}`}
        className={`transition-all w-full duration-500 ease-in-out aspect-[1/1] md:aspect-[495/666]`}
        imgClassName="aspect-[1/1] md:aspect-[495/666] w-full"
        maxWidth="1000"
      />
      <div
        className={`absolute swatch-title pl-2 pr-10 pt-2 top-0 left-0 z-[1] text-[1.25rem] stack`}
      >
        <span className="leading-none text-base text-black">
          {thumbnail.title}
        </span>
        <If condition={thumbnail.subtitle}>
          <span className="text-sm text-gold">{thumbnail.subtitle}</span>
        </If>
      </div>
    </div>
  );
};

const CardItem = ({ thumbnail, index, currentIndex, ...props }) => {
  const tags = thumbnail.contentfulMetadata?.tags || [];
  const prefix = thumbnail?.pageType
    ? `/${thumbnail.pageType}`
    : getPrefix(tags);
  return (
    <Card
      image={
        thumbnail?.cardImage?.[0]?.cdn_url || thumbnail?.image?.[0]?.cdn_url
      }
      title={thumbnail.title}
      description={thumbnail.cardDescription || thumbnail.description}
      imageCover={thumbnail.imageCover !== false}
      href={thumbnail.handle ? `${prefix}/${thumbnail.handle}` : thumbnail.link}
      linkText={thumbnail.linkText}
      showResources={thumbnail.showResources || false}
      showSolutions={thumbnail.showSolutions}
      solutions={thumbnail.solutions}
      cardType={thumbnail.cardType}
      subtitle={thumbnail.cardSubtitle || thumbnail.subtitle}
    />
  );
};

const NoScroll = ({ block, className = "", contentRef = null, ...props }) => {
  const { backgroundColor, showTitle, title } = block;
  const ctas = block?.ctAsCollection?.items || [];
  const thumbnails = block?.carouselCollection?.items || [];
  const padding = Padding[block.padding || "None"];
  const backgroundColorText = BackgroundColorText[backgroundColor];
  const bgColor = BackgroundColors[backgroundColor];

  return (
    <section
      className={`${styles.carousel} ${padding} ${bgColor} ${backgroundColorText} relative overflow-hidden stack gap-16`}
      {...props}
    >
      <If condition={showTitle && title}>
        <Heading as="h2" bottomBorder isContainer>
          {title}
        </Heading>
      </If>
      <div className="stack gap-4">
        <div className="w-full overflow-visible relative">
          <div
            className={clsx(
              "flex justify-center mx-auto max-w-[100dvw] gap-16"
            )}
            ref={contentRef}
          >
            <For
              each={thumbnails}
              render={(thumbnail, index) => {
                if (block.carouselType === "Case Studies") {
                  return (
                    <CaseStudy
                      thumbnail={thumbnail}
                      index={index}
                      currentIndex={0}
                      {...props}
                    />
                  );
                }
                if (block.carouselType === "Card") {
                  if (thumbnail.cardType === "Swatch") {
                    return (
                      <Swatch
                        thumbnail={thumbnail}
                        index={index}
                        currentIndex={0}
                        {...props}
                      />
                    );
                  }
                  return (
                    <CardItem
                      thumbnail={thumbnail}
                      index={index}
                      currentIndex={0}
                      {...props}
                    />
                  );
                }
              }}
            />
          </div>
          <If condition={block.carouselType === "Case Studies"}>
            <Parallax
              className="absolute right-0 top-40 z-0 w-[50vw] max-w-[500px] pointer-events-none"
              speed={0.5}
            >
              <svg
                viewBox="0 0 706 1113"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 544L365 271.091L0 271V544Z" fill="#fff" />
                <path
                  d="M365 1113L730 840.091L365 840V1113Z"
                  fill="#fff"
                  fillOpacity={0.6}
                />
                <path d="M364 273L729 0.0905172L364 0V273Z" fill="#fff" />
                <path d="M364 836L729 563.091L364 563V836Z" fill="#fff" />
              </svg>
            </Parallax>
          </If>
        </div>
      </div>

      <CTAs ctas={ctas} />
    </section>
  );
};

const Scrollable = ({ block, className = "", contentRef = null, ...props }) => {
  const { backgroundColor, showTitle, title } = block;
  const ctas = block?.ctAsCollection?.items || [];
  const thumbnails =
    block?.imageCarousel || block?.carouselCollection?.items || [];
  const padding = Padding[block.padding || "None"];
  const backgroundColorText = BackgroundColorText[backgroundColor];
  const bgColor = BackgroundColors[backgroundColor];

  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: block.carouselType === "Case Studies",
    align: block.carouselType === "Case Studies" ? "center" : "start",
    startIndex: 0, //block.carouselType === "Case Studies" ? 1 : 0,
    containScroll: false,
  });

  const onInit = useCallback((embla) => {
    setScrollSnaps(embla.scrollSnapList());
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [openProject, setOpenProject] = useState(null);

  const prevSlide = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const nextSlide = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      // console.log(title, emblaApi);
      // Initialize Embla Carousel
      emblaApi.on("select", () => {
        // console.log("select");
        setCurrentIndex(emblaApi.selectedScrollSnap());
        // onChange(emblaApi.selectedScrollSnap());
      });
      emblaApi.on("settle", () => {
        // setSelected(embla.selectedScrollSnap());
      });
      emblaApi.on("resize", () => {});

      onInit(emblaApi);
    }
  }, [emblaApi]);

  // useEffect(() => {
  //   if (emblaApi) {
  //     setCurrentIndex(0);
  //     setTimeout(() => {
  //       emblaApi.scrollTo(0);
  //     }, 1000);
  //   }
  // }, [emblaApi, block]);

  return (
    <section
      className={`${styles.carousel} ${padding} ${bgColor} ${backgroundColorText} relative overflow-hidden stack gap-16`}
      {...props}
    >
      <If condition={showTitle && title}>
        <Heading as="h2" bottomBorder isContainer>
          {title}
        </Heading>
      </If>
      <div className="stack gap-4">
        <div ref={emblaRef} className="w-full overflow-visible relative">
          <div
            className={clsx("embla__container flex w-full md:gap-8 md:ml-8")}
            ref={contentRef}
          >
            <For
              each={thumbnails}
              render={(thumbnail, index) => {
                if (block.carouselType === "Image") {
                  return (
                    <Image
                      src={`${thumbnail?.cdn_url}?width=1000`}
                      imgClassName="h-[500px] w-auto max-w-none"
                      alt={thumbnail?.description}
                      cover={false}
                      height={thumbnail?.height}
                      width={thumbnail?.width}
                    />
                  );
                }
                if (block.carouselType === "Case Studies") {
                  return (
                    <CaseStudy
                      thumbnail={thumbnail}
                      index={index}
                      currentIndex={currentIndex}
                      {...props}
                    />
                  );
                }
                if (block.carouselType === "Card") {
                  if (thumbnail.cardType === "Swatch") {
                    return (
                      <Swatch
                        thumbnail={thumbnail}
                        index={index}
                        currentIndex={currentIndex}
                        {...props}
                      />
                    );
                  }
                  return (
                    <CardItem
                      thumbnail={thumbnail}
                      index={index}
                      currentIndex={currentIndex}
                      {...props}
                    />
                  );
                }
              }}
            />
          </div>
          <If condition={block.carouselType === "Case Studies"}>
            <Parallax
              className="absolute right-0 top-40 z-0 w-[50vw] max-w-[460px] pointer-events-none opacity-50"
              speed={0.35}
            >
              <svg
                viewBox="0 0 706 1113"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 544L365 271.091L0 271V544Z"
                  fill="#fff"
                  fillOpacity={0.6}
                />
                <path d="M365 1113L730 840.091L365 840V1113Z" fill="#fff" />
                <path
                  d="M364 273L729 0.0905172L364 0V273Z"
                  fill="#fff"
                  fillOpacity={0.8}
                />
                <path
                  d="M364 836L729 563.091L364 563V836Z"
                  fill="#fff"
                  fillOpacity={0.8}
                />
              </svg>
            </Parallax>
          </If>
        </div>

        <div className="flex container mx-auto flex-col lg:flex-row justify-between gap-2 mt-4 items-center px-gutter">
          <div className="flex gap-2">
            <For
              each={scrollSnaps}
              render={(thumb, idx) => {
                return (
                  <button
                    onClick={() => {
                      emblaApi?.scrollTo(idx);
                      setCurrentIndex(idx);
                    }}
                    className={`border ${
                      currentIndex === idx
                        ? "bg-gold border-gold"
                        : backgroundColor === "Black"
                          ? "bg-white border-white"
                          : "bg-black border-black"
                    } aspect-square w-3 h-3 overflow-hidden`}
                  >
                    <span className="sr-only">{idx}</span>
                  </button>
                );
              }}
            />
          </div>
          <div className="hidden md:flex gap-2">
            <button
              className="embla__button embla__button--prev"
              onClick={prevSlide}
            >
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="16.5"
                  cy="16.5002"
                  r="16.5"
                  transform="rotate(176.915 16.5 16.5002)"
                  fill="#B18626"
                />
                <path
                  d="M20.4523 24.7993L11.3102 16.9103L19.5515 8.08445"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              className="embla__button embla__button--next "
              onClick={nextSlide}
            >
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16.5" cy="16.5" r="16.5" fill="#B18626" />
                <path
                  d="M13 8L21.7043 16.3696L13 24.7391"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <CTAs ctas={ctas} algin="center" />
    </section>
  );
};

/**
 *
 * Carousel
 *
 */
const Carousel = ({ block, className, ...props }: CarouselProps) => {
  const { backgroundColor, showTitle, title } = block;
  const thumbnails =
    block?.imageCarousel || block?.carouselCollection?.items || [];
  const contentRef = useRef(null);
  const [centerIt, setCenterIt] = useState(false);

  console.log(block?.imageCarousel);

  const handleResize = () => {
    if (contentRef.current) {
      const firstChild = contentRef?.current?.firstChild;
      const fullWidth =
        firstChild?.offsetWidth * thumbnails.length +
        thumbnails.length * 32 +
        32;
      // console.log(title, "resizing");
      // console.log(fullWidth, window.innerWidth);
      if (fullWidth < window.innerWidth) {
        setCenterIt(true);
      } else if (centerIt) {
        setCenterIt(false);
      }
    }
  };

  const ref = useResizeObserver(handleResize);

  if (centerIt && block.carouselType !== "Case Studies") {
    return (
      <NoScroll
        block={block}
        className={className}
        contentRef={contentRef}
        {...props}
      />
    );
  }
  return (
    <Scrollable
      block={block}
      className={className}
      contentRef={contentRef}
      {...props}
    />
  );
};

export default Carousel;
